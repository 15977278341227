<template>
  <div
    v-if="collection"
    class="page-collection"
    itemscope
    itemtype="http://schema.org/ProductCollection"
  >
    <collection-banner
      :collection="collection"
      :ratio="0.15"
      image-alignment="top"
      class="overflow-hidden"
      :class="[
        viewportSize.isGreater('md') ? 'rounded-md' : '',
        !seoDescription
          ? !viewportSize.isGreater('sm')
            ? 'mb-1'
            : 'mb-4'
          : '',
      ]"
    />
    <tml-collapsible-text
      v-if="collection.seoDescription"
      :text="collection.seoDescription"
      class="mt-2"
      :class="{
        'mb-4': viewportSize.isGreater('sm'),
      }"
    />
    <template v-if="products">
      <tml-split-test name="collection-icons-relaunch">
        <tml-split-test-variation name="control" default>
          <collection-new-filter
            v-if="viewportSize.isGreater('md')"
            :products="products"
            :filter="filter"
            :filters="filters"
            :show-header="viewportSize.isGreater('lg')"
            @filter-set="getProducts($event)"
            @sort="
              sort => {
                sortProducts(sort);
              }
            "
          />
        </tml-split-test-variation>
        <tml-split-test-variation name="with-icons">
          <collection-new-filter
            v-if="viewportSize.isGreater('md')"
            :products="products"
            :filter="filter"
            :filters="filters"
            show-header
            show-filter-icons
            show-color-squares
            @filter-set="getProducts($event)"
            @sort="
              sort => {
                sortProducts(sort);
              }
            "
          />
        </tml-split-test-variation>
      </tml-split-test>
    </template>

    <div
      v-if="!viewportSize.isGreater('md')"
      id="mobile-filter"
      class="section-mobile-filter pt-4 mb-4"
    >
      <div class="row">
        <tml-sidebar name="collection-filter" align="right">
          <div>
            <collection-filter
              v-if="products"
              :products="products"
              :filter="filter"
              :filters="filters"
              @filter-set="getProducts($event)"
            />
          </div>
        </tml-sidebar>
        <div class="pr-0 col-7">
          <collection-sort
            @sort="
              sort => {
                sortProducts(sort);
              }
            "
          />
        </div>

        <div class="col-5">
          <!-- Button uses form colours to be match the 'Sort by' selector -->
          <tml-button
            class="h-full whitespace-nowrap"
            text="Filters"
            :icon="faSlidersH"
            border-color="var(--tml-form-border-color)"
            text-color="var(--tml-form-text-secondary-color)"
            @click="
              $store.dispatch('overlayElements/toggle', 'collection-filter')
            "
          />
        </div>
      </div>
    </div>
    <tml-loader :loading="loadingProducts" :loading-ratio="0.5">
      <div v-if="products && !products.length">
        <tml-message :icon="faSearch" text="Sorry, no products were found." />
      </div>
      <collection-product-list v-if="products" :products="products" />
    </tml-loader>
    <collection-review-summary
      v-show="!loadingProducts"
      :collection="collection"
    />
  </div>
  <tml-spinner v-else height="50vh" />
</template>

<script>
import {formatUrl, viewportSize} from '@teemill/common/helpers';
import {snackbar} from '@teemill/common/services';
import {splitTests} from '@teemill/common/classes';
import {escapeJSON, removeNonStandardCharacters} from '@teemill/utilities';
import {NotFoundError, ApiError} from '@teemill/common/errors';
import {faSlidersH, faSearch} from '@fortawesome/pro-light-svg-icons';
import {tracker} from '@teemill/common/plugins';
import {useHead} from '@vueuse/head';
import {computed} from 'vue';
import {captureException} from '@sentry/vue';

import {
  CollectionBanner,
  CollectionProductList,
  CollectionFilter,
  CollectionNewFilter,
  CollectionSort,
  CollectionReviewSummary,
} from './partials';

export default {
  name: 'CollectionPage',

  components: {
    CollectionBanner,
    CollectionProductList,
    CollectionFilter,
    CollectionNewFilter,
    CollectionSort,
    CollectionReviewSummary,
  },

  data() {
    return {
      faSlidersH,
      faSearch,

      splitTests,

      sortBy: null,
      filter: {
        filteredProducts: [],
        filterOptions: {
          types: {
            multiselect: true,
            options: [],
            filterableOptions: [],
          },
          sizes: {
            multiselect: true,
            options: [],
            filterableOptions: [],
          },
          colours: {
            multiselect: true,
            options: [],
            filterableOptions: [],
          },
        },
        stagedFiltered: [],
        filterMeta: {
          enableFilters: false,
          enabledFilters: 0,
        },
      },
      collection: null,
      products: null,
      formatUrl,
      filters: null,
      colours: null,
      sizes: null,
      types: null,
      sort: null,
      loadingProducts: true,

      meta: {},
      viewportSize,
      longDescriptionLength: 150,
    };
  },

  computed: {
    filtersEmpty() {
      return (
        this.filter.filterMeta.enableFilters &&
        !this.filter.filteredProducts.length > 0
      );
    },

    url() {
      const division = this.$store.state.subdomain.division;

      return `/omnis/v3/division/${division}/products/`;
    },

    isRapanui() {
      return this.$store.state.subdomain?.division === 14;
    },

    descriptionLength() {
      return this.collection?.description?.length ?? 0;
    },
  },

  watch: {
    ['$route.params.collection']: {
      immediate: true,
      handler(value) {
        if (!value || this.collection?.urlName === value) {
          return;
        }

        this.collection = null;
        this.products = null;

        this.fetchData(value);
      },
    },

    filters: {
      handler(filters) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            ...filters,
          },
        });
      },
    },
  },

  created() {
    useHead(
      computed(() => {
        if (this.collection && this.products) {
          const host = `${location.protocol}//${location.hostname}`;

          const items = this.products
            .map((product, index) => {
              if (product) {
                const productUrl = `${host}/product/${product.urlName}/`;

                return `
                {
                  "@type": "ListItem",
                  "position": "${index + 1}",
                  "item": {
                    "@type": "Product",
                    "name": "${escapeJSON(product.name)}",
                    "sku": "${product.id}",
                    "url": "${productUrl}",
                    "image": "${product.collectionImage}",
                    "description": "${this.getEscapedDescription(product)}",
                    "brand": {
                      "@type": "Brand",
                      "name": "${this.$store.state.subdomain.company.name}"
                    },
                    "offers": [
                      ${this.$store.getters['currency/getLdJson']({
                        price: product.price,
                        url: productUrl,
                      }).join(',')}
                    ]
                  }
                }
              `;
              }

              return null;
            })
            .filter(item => item);

          return {
            title: this.collection.meta.title
              ? this.collection.meta.title
              : this.$store.state.title,

            script: [
              {
                type: 'application/ld+json',
                children: `
                {
                  "@context": "http://schema.org/",
                  "@type": "WebPage",
                  "name": "${escapeJSON(this.collection.title)}",
                  "url": "${host}${this.$route.fullPath}",
                  "image": "${this.collection.meta.image}",
                  "description": "${escapeJSON(this.collection.description)}"
                }
              `,
              },
              {
                type: 'application/ld+json',
                children: `
                {
                  "@context": "http://schema.org/",
                  "@type": "ItemList",
                  "name": "${escapeJSON(this.collection.title)}",
                  "url": "${host}${this.$route.fullPath}",
                  "numberOfItems": ${this.products.length},
                  "itemListElement": [
                    ${items.join(',')}
                  ]
                }
              `,
              },
            ],

            meta: [
              {
                vmid: 'og:title',
                property: 'og:title',
                content: this.collection.meta.title
                  ? this.collection.meta.title
                  : this.$store.state.title,
              },
              {
                vmid: 'og:description',
                property: 'og:description',
                content: this.collection.meta.description
                  ? this.collection.meta.description
                  : this.collection.description,
              },
              {
                vmid: 'og:image',
                property: 'og:image',
                content: this.collection.meta.image,
              },
              {vmid: 'og:type', property: 'og:type', content: 'website'},

              {
                vmid: 'twitter:title',
                property: 'twitter:title',
                content: this.collection.meta.title
                  ? this.collection.meta.title
                  : this.$store.state.title,
              },
              {
                vmid: 'twitter:card',
                property: 'twitter:card',
                content: 'photo',
              },
              {
                vmid: 'twitter:image',
                property: 'twitter:image',
                content: this.collection.meta.image,
              },
              {
                vmid: 'description',
                name: 'description',
                content: this.collection.meta.description
                  ? this.collection.meta.description
                  : this.collection.description,
              },
            ],
            link: [
              {
                vmid: 'canonical',
                rel: 'canonical',
                href: `${host}/collection/${this.collection.urlName}/`,
              },
            ],
          };
        }

        return {};
      })
    );
  },

  deactivated() {
    this.$store.commit('collection/clearActive');
  },

  methods: {
    fetchData(collection) {
      const collectionRequest = this.getCollection(collection);

      const productsRequest = this.getProductsFromQueryParameters();

      Promise.all([collectionRequest, productsRequest]).then(() => {
        if (this.collection && this.products) {
          this.$store.commit(
            'collection/setLastVisited',
            Object.assign({}, this.collection, {
              products: this.products.map(product => product.id),
            })
          );

          this.$store.commit('collection/setActive', this.collection);

          this.$store.dispatch('setTitle', this.collection.title);
        }

        this.$eventBus.emit('tml-breadcrumbs-update');
      });

      this.getFilterOptions();
    },

    getCollection(collection) {
      return fetch(
        formatUrl(
          `/omnis/v3/division/${this.$store.state.subdomain.division}/collections/${collection}/`
        ),
        {
          credentials: 'include',
          mode: 'no-cors',
        }
      )
        .then(response => {
          if (response.ok) {
            return response.json();
          }

          if (response.status === 404) {
            throw new NotFoundError();
          }

          throw new ApiError();
        })
        .then(data => {
          this.collection = data;

          tracker.event('view-collection', {
            items: [this.collection.urlName],
          });
        })
        .catch(error => {
          if (error instanceof NotFoundError) {
            this.$router.replace('/404/');
            return;
          }

          if (error instanceof ApiError) {
            snackbar.error('Unable to load collection data, try again later.');
            return;
          }

          captureException(error);
        });
    },
    sortProducts(sortType = null) {
      if (sortType) {
        this.sort = sortType;
      } else {
        this.sort = null;
      }

      this.loadProducts();
    },

    getProducts(filters = null) {
      this.filters = Object.assign({}, filters);

      if (filters) {
        if (filters.colours.length) {
          this.colours = filters.colours;
        } else {
          this.colours = null;
        }

        if (filters.sizes.length) {
          this.sizes = filters.sizes;
        } else {
          this.sizes = null;
        }

        if (filters.types.length) {
          this.types = filters.types;
        } else {
          this.types = null;
        }
      }

      return this.loadProducts();
    },

    loadProducts() {
      this.loadingProducts = true;

      return this.axios
        .get(formatUrl(this.url), {
          params: {
            collection: this.$route.params.collection,
            colours: this.colours,
            sizes: this.sizes,
            types: this.types,
            sort: this.sort,
          },
        })
        .success(data => {
          this.products = data;
          this.loadingProducts = false;
        })
        .handle(404, () => {
          this.$router.replace('/404/');
        })
        .oops('Unable to load product data, try again later.');
    },

    getEscapedDescription(product) {
      return removeNonStandardCharacters(
        escapeJSON(
          product.shortDescription
            ? product.shortDescription
            : product.description
        )
      );
    },

    getProductsFromQueryParameters() {
      const queryParameters = this.$route.query;

      const filterValue = {
        colours: [],
        types: [],
        sizes: [],
      };

      Object.keys(filterValue).forEach(filterType => {
        const queryValue = queryParameters[filterType];

        if (queryValue) {
          filterValue[filterType] =
            typeof queryValue === 'string' ? queryValue.split(',') : queryValue;
        }
      });

      return this.getProducts(filterValue);
    },

    getFilterOptions() {
      return this.axios
        .get(
          formatUrl(
            `/omnis/v3/division/${this.$store.state.subdomain.division}/collections/${this.$route.params.collection}/options/`
          )
        )
        .success(data => {
          this.loadedFilterOptions = true;
          this.filter.filterOptions.colours.filterableOptions = data.colors.map(
            color => ({
              property: color,
              selected: this.filters.colours.includes(color),
            })
          );
          this.filter.filterOptions.sizes.filterableOptions = data.sizes.map(
            size => ({
              property: size,
              selected: this.filters.sizes.includes(size),
            })
          );
          this.filter.filterOptions.types.filterableOptions = data.types.map(
            type => ({
              property: type,
              selected: this.filters.types.includes(type),
            })
          );
        })
        .oops(
          "Oops.. we couldn't load your filter options, please try again later."
        );
    },
  },
};
</script>

<style scoped lang="scss">
.page-collection {
  .collection-filter-container {
    min-height: 280px;
  }

  .section-desktop-filter {
    border-radius: $border-radius;
    min-height: calc(4rem + 2px);
  }

  .section-mobile-filter {
    background-color: var(--tml-page-background-color);
    min-height: calc(4rem + 2px);
  }
}

.heading {
  font-size: 3.5rem;
}

@include media-breakpoint-down(sm) {
  .heading {
    font-size: 2.5rem;
  }
}
</style>
