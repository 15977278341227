<script setup lang="ts">
import {ref} from 'vue';
import {store} from '@teemill/modules/store-front/services';
import {http} from '@teemill/common/services';
import {formatUrl, viewportSize} from '@teemill/common/helpers';
import {Collection} from '@teemill/common/models';

const props = defineProps<{collection: Collection}>();

const averageRating = ref(0);
const totalReviews = ref(0);

http
  .get(
    formatUrl(
      `/omnis/v3/division/${store.state.subdomain.division}/collections/${props.collection.urlName}/reviews/aggregate`
    )
  )
  .success((data: {averageScore: number; reviewCount: number}) => {
    averageRating.value = Math.round(data.averageScore * 10) / 10;
    totalReviews.value = data.reviewCount;
  });
</script>

<template>
  <div v-if="totalReviews" itemprop="aggregateRating" itemscope itemtype="http://schema.org/AggregateRating" class="center p-6">
    <meta itemprop="ratingValue" :content="averageRating.toString()" />
    <meta itemprop="reviewCount" :content="totalReviews.toString()" />
    <p>
      <tml-star-rating
        class="align-bottom" :rating="averageRating" :class="{
          'inline': viewportSize.isGreater('sm')
        }"
      />
      Products in this collection average {{ averageRating }} stars from {{ totalReviews }} customer reviews
    </p>
  </div>
</template>