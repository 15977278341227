<template>
  <div class="collection-banner">
    <meta itemprop="name" :content="collection.title" />
    <tml-slim-banner
      v-if="collection && collection.bannerImage"
      :title="collection.title"
      :copy="bannerDescription"
      :image="collection.bannerImage"
      :font="theme().get('heading1.font')"
      :text-shadow="textShadow"
      :image-alignment="imageAlignment"
      :ratio="ratio"
      vertical-alignment="bottom"
      :alt-text="collection.bannerImageAlt"
    />
  </div>
</template>

<script>
import {formatUrl} from '@teemill/common/helpers';
import {toTextShadow} from '@teemill/modules/page-framework';

export default {
  name: 'SectionCollectionBanner',

  inject: ['theme'],

  props: {
    collection: [Array, Object],
    noDescription: Boolean,
    ratio: {
      type: Number,
      default: 0.4,
    },
    imageAlignment: {
      type: String,
      default: 'center',
    },
  },

  computed: {
    bannerDescription() {
      return this.noDescription ? '' : this.collection.description;
    },

    textShadow() {
      const themeValue = this.theme().get('banner.text.shadow');
      return themeValue ? toTextShadow(themeValue) : '';
    },
  },

  methods: {formatUrl},
};
</script>

<style lang="scss" scoped>
.collection-banner {
  min-height: 300px;
}

@media (max-width: 768px) {
  .collection-banner {
    margin: 0 -1rem 1rem;
  }
}
</style>
